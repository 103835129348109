<script>
  import { onDestroy, setContext, getContext } from 'svelte';
  import { langKey } from './data/config.js';
  import { domains, community, langs, diplomas } from './data/stores.js';
  import { displayOptions, displayOptionFilters } from './data/stores.js';
  import { mainJobs, otherJobs, tags, tagFilters } from './data/stores.js';

  import Title from './components/Title.svelte';
  import Filters from './components/Filters.svelte';
  import Intro from './components/Intro.svelte';
  import Jobs from './components/Jobs.svelte';
  import TasksPart from './components/TasksPart.svelte';

  setContext(langKey, 'fr');
</script>

<svelte:head>
  <link
    href="https://fonts.googleapis.com/css?family=Lato:400,700|Merriweather&display=swap"
    rel="stylesheet" />
</svelte:head>

<Title />
<Filters all={$displayOptions} filtered={displayOptionFilters} />
<Filters all={$tags} filtered={tagFilters} />
{#if $displayOptionFilters.includes('intro')}
  <Intro />
{/if}
{#if $displayOptionFilters.includes('domains')}
  <TasksPart tasks={$domains}>Domaines d'intervention</TasksPart>
{/if}
{#if $displayOptionFilters.includes('mainJobs') && $mainJobs.length > 0}
  <Jobs filters={$tagFilters.join(', ')} jobs={$mainJobs}>
    {#if $tagFilters.length}
      Expériences principales
    {:else}Expériences récentes{/if}
  </Jobs>
{/if}
{#if $displayOptionFilters.includes('community')}
  <TasksPart tasks={$community}>Communité et partage</TasksPart>
{/if}
{#if $displayOptionFilters.includes('langs')}
  <TasksPart tasks={$langs}>Langues</TasksPart>
{/if}
{#if $displayOptionFilters.includes('diplomas')}
  <TasksPart tasks={$diplomas}>Formation initiale et continue</TasksPart>
{/if}
{#if $displayOptionFilters.includes('otherJobs')}
  <Jobs jobs={$otherJobs}>
    {#if $displayOptionFilters.includes('mainJobs') && $mainJobs.length > 0}
      Autres expériences
    {:else}Expériences{/if}
  </Jobs>
{/if}
