<script>
  import Separator from './Separator.svelte';
  import { onDestroy } from 'svelte';

  export let all = [];
  export let filtered = [];
</script>

<div class="part filters">
  {#each all as tag}
    <label
      for={'tag-' + tag}
      class:active={$filtered.includes(tag)}
      class="filters--button">
      {tag}
    </label>
    <input
      class="hidden"
      type="checkbox"
      bind:group={$filtered}
      id={'tag-' + tag}
      value={tag} />
  {/each}
</div>
<Separator cssClass="filters"/>
