<script>
  import PartTitle from './PartTitle.svelte';
  import Task from './Task.svelte';

  export let tasks;
</script>

<PartTitle>
  <slot />
</PartTitle>
<ul class="tasklist">
  {#each tasks as task}
    <Task task={task.task} />
  {/each}
</ul>
