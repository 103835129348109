<script>
  export let task;
</script>

{#if task.name || task.subtasks}
  <div class="task--container">
    {#if task.name}
      <li class:task--withlist={task.subtasks}>
        {@html task.name}
      </li>
    {/if}
    {#if task.subtasks}
      <ul class="tasklist">
        {#each task.subtasks as subtask}
          <svelte:self task={subtask} />
        {/each}
      </ul>
    {/if}
  </div>
{/if}
