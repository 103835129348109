<script>
  import Separator from './Separator.svelte';
  const descriptor = 'Professionnel du Web';
</script>

<a class="no-underline cursor-pointer" href="https://boris.schapira.dev">
  <div class="flex items-center">
    <img
      src="https://avatars.schapira.dev/2017.jpg"
      alt="lovely avatar"
      class="w-16 h-16 rounded-full" />
    <h1
      class="font-semibold font-sans text-2xl lg:text-3xl ml-5 text-gray-700
      leading-tight">
        <small class="text-sm text-gray-900 text-opacity-25">https://</small>
        Boris<small class="text-sm text-gray-900 text-opacity-25">.</small>Schapira<small class="text-sm text-gray-900 text-opacity-25">.dev</small>
        <small class="font-bold block text-gray-900">{descriptor}</small>
    </h1>
  </div>
</a>
<Separator />
