import { writable, readable, derived } from 'svelte/store';
import jobsData from './jobs.yaml';
import domainsData from './domains.yaml';
import communityData from './community.yaml';
import langsData from './langs.yaml';
import diplomasData from './diplomas.yaml';

export const displayOptions = readable([
  'intro',
  'domains',
  'mainJobs',
  'community',
  'langs',
  'diplomas',
  'otherJobs',
  'jobTasks',
  'jobTechEnv',
]);
export const displayOptionFilters = writable([
  'mainJobs',
  'otherJobs',
  'jobTasks',
  'jobTechEnv',
]);

let uid = 1;
let myJobs = jobsData
  .map((j) => {
    j.id = uid++;
    return j;
  })
  .sort(sortByDates);

function sortByDates(i, j) {
  function computeDurationAndToAndType(job) {
    let to, duration, t;
    if (job.type && job.type == 'CDI') t = 2;
    else t = 1;
    if (job.to) {
      to = job.to;
      duration = (job.to - job.from) * 12;
    } else {
      if (job.duration) {
        to = job.from + job.duration / 12;
        duration = job.duration;
      } else {
        to = duration = Infinity;
      }
    }
    return [to, duration, t];
  }

  const [ito, idu, it] = computeDurationAndToAndType(i);
  const [jto, jdu, jt] = computeDurationAndToAndType(j);

  if (it > jt) {
    return -1;
  } else {
    if (ito > jto) {
      return -1;
    }
    if (ito < jto) {
      return 1;
    }
    if (idu > jdu) {
      return -1;
    }
    if (idu < jdu) {
      return 1;
    }
  }
  return 0;
}

export const tagFilters = writable([]);
export const jobs = readable(myJobs);

export const mainJobs = derived([jobs, tagFilters], ([$jobs, $tagFilters]) =>
  $tagFilters.length
    ? $jobs
        .filter((j) => j.tags.some((r) => [...$tagFilters].includes(r)))
        .sort(sortByDates)
    : $jobs.slice(0, 3)
);
export const otherJobs = derived([jobs, tagFilters], ([$jobs, $tagFilters]) =>
  $tagFilters.length
    ? $jobs
        .filter((j) => !j.tags.some((r) => [...$tagFilters].includes(r)))
        .sort(sortByDates)
    : $jobs.slice(3)
);

export const tags = derived(jobs, ($jobs) =>
  Array.from(new Set($jobs.map((j) => j.tags).flat(Infinity))).sort()
);

export const domains = readable(domainsData);
export const community = readable(communityData);
export const langs = readable(langsData);
export const diplomas = readable(diplomasData);
