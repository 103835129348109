<script>
  import PartTitle from './PartTitle.svelte';
  import Task from './Task.svelte';

  import { displayOptionFilters } from '../data/stores.js';

  import { quintOut } from 'svelte/easing';
  import { crossfade } from 'svelte/transition';
  import { flip } from 'svelte/animate';

  const [send, receive] = crossfade({
    duration: d => Math.sqrt(d * 200),
    fallback(node, params) {
      const style = getComputedStyle(node);
      const transform = style.transform === 'none' ? '' : style.transform;

      return {
        duration: 500,
        easing: quintOut,
        css: t => `
					transform: ${transform};
					opacity: ${t}
				`
      };
    }
  });

  export let jobs,
    filters = [];
</script>

<PartTitle>
  <slot />
  {#if filters.length > 0}({filters}){/if}
</PartTitle>
{#each jobs as job (job.id)}
  <div
    class="job"
    id="job-{job.id}"
    in:receive={{ key: job.id }}
    out:send={{ key: job.id }}
    animate:flip={{ duration: 500 }}>
    <h3 class="job--title">
      {@html job.title}
      ,
      {@html job.company}
      <span class="job--dates">
        {#if job.from}
          {@html job.from}
          {#if job.to}
            –
            {@html job.to}
          {/if}
          {#if job.duration || job.type}
            (
            {#if job.duration}
              {@html job.duration}
              mois
            {/if}
            {#if job.duration && job.type}, {/if}
            {#if job.type}
              {@html job.type}
            {/if}
            )
          {/if}
        {/if}
      </span>
    </h3>
    {#if job.excerpt}
      <p>
        {@html job.excerpt}
      </p>
    {/if}
    {#if $displayOptionFilters.includes('jobTasks') && job.task}
      <Task task={job.task} />
    {/if}
    {#if $displayOptionFilters.includes('jobTechEnv') && job.techEnv}
      <p>
        <span class="line--title">Environnement(s) technique(s)</span>
        :
        {@html job.techEnv}
      </p>
    {/if}

  </div>
{/each}
